import Vue from 'vue'
import { decode } from '@/lib/util'
import { on } from 'dom-event'
import { Swiper, Scrollbar, Navigation, Pagination, Autoplay, Thumbs, Mousewheel } from 'swiper'
Swiper.use([Scrollbar, Navigation, Pagination, Autoplay, Thumbs, Mousewheel])

Vue.component('carousel-swiper', {
  props: ['dataOptions', 'checkCarousel', 'destroyOnDesktop'],
  data() {
    return {
      swiper: null,
      options: JSON.parse(decode(this.dataOptions)),
      id: 'id-' + (new Date()).getTime()
    }
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      this.handleResize()
      let windowWidth = window.innerWidth
      on(window, 'resize', () => {
        if (window.innerWidth != windowWidth) {
          this.handleResize()
          windowWidth = window.innerWidth
        }
      })
      on(window, 'orientationchange', this.handleResize)
    })
  },
  methods: {
    initSwiperSlider() {
      let is_carousel = true

      if (this.checkCarousel) {
        const breakpoints = this.options.breakpoints ? this.options.breakpoints : []
        const windowInnerWidth = window.innerWidth
        let slidesPerView = 0
        const swiperWrapperEl = this.$refs.swiperWrapper
        const countChildEl = swiperWrapperEl && swiperWrapperEl.children ? swiperWrapperEl.children.length : 0
        Object.keys(breakpoints).forEach(breakpoint => {
          if (breakpoint <= windowInnerWidth) {
            slidesPerView = Math.floor(parseInt(breakpoints[breakpoint].slidesPerView))
          }
        })

        if (countChildEl <= slidesPerView) {
          is_carousel = false
        }
      }

      if (is_carousel) {
        this.swiper = new Swiper(this.$refs.swiperEl, this.options)
      }
    },
    handleResize() {
      if (this.destroyOnDesktop && window.innerWidth > 640) {
        this.destroySwiper()
      } else if (!this.swiper) {
        this.initSwiperSlider()
      }
    },
    destroySwiper() {
      if (this.swiper) {
        this.swiper.destroy(true, true)
        this.swiper = null
      }
    }
  }
})
